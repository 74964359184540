import React from "react"

import Layout from "../components/layout"
import BGSlideshow from "../components/bg_slideshow"
import { Parallax } from 'react-parallax';
import SEO from "../components/seo"



import styles from "./index.module.scss"

import SL1 from "../images/Lau_2.jpg"
import SL2 from "../images/P1060399.jpg"
import SL3 from "../images/20160907_180128.jpg"
import SL4 from "../images/20170913_134523.jpg"
import SL5 from "../images/20170914_173932.jpg"
import SL6 from "../images/65569479_2309234605790923_8219389329374445568_o.jpg"
import SL7 from "../images/62500280_2277193692328348_9210242686903123968_o.jpg"

import Raps from "../images/landschaft.jpg"
import LS from "../images/foto_lau_senior_2_small.jpg"



export default () => (
    <Layout allwaysTop={false} active="/unternehmen">
        <SEO title="Schwertransport Lau" />


        <BGSlideshow style={{zIndex:-1}} entries={
            [
                {bgImage: SL1, animationDelay: 0, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL2, animationDelay: 6, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL3, animationDelay: 12, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL4, animationDelay: 18, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL5, animationDelay: 24, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL6, animationDelay: 30, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                {bgImage: SL7, animationDelay: 36, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
            ]} />

        <article style={{background:'white'}}>
        <div className={styles.content_div} style={{marginTop: '100vh'}}>
                <h2>Transportkompetenz seit fast 30 Jahren, dafür stehen wir</h2>
                <h3>Sicherheit</h3>
                <p>Steht bei uns an erster Stelle. Wir bewegen uns im öffentlichen Straßenverkehr. Im Extremfall mit über 100 Tonnen oder 60 Meter Länge. Eine gewissenhafte Planung und verantwortungsbewusste Durchführung sind Voraussetzung dafür.</p>
                <p>Die Einhaltung gesetzlicher Vorschriften ist einer der wichtigsten Bestandteile unserer täglichen Arbeit.</p>
                <h3>Kundenorientierung</h3>
                <p>Wir sind stets auf der Suche nach der wirtschaftlichsten Lösung für unsere Kunden. Beratung und eine gemeinsame Lösungsfindung sind Bestandteile unserer täglichen Arbeit.</p>
                <h3>Professionelle Mitarbeiter</h3>
                <p>Wir fördern und schulen. So hat der Kunde stets Profis vor Ort.</p>
                <h3>Verantwortung</h3>
                <p>Wir sind auch in schwierigen Momenten der Partner an Ihrer Seite, stellen uns täglich Notfällen, Sonderwünschen, komplexen Projekten, Ideen, strengsten Anforderungen sowie einer nachhaltigen Unternehmensführung.</p>
                <h3>Innovative Technik und Lösungen</h3>
                <p>Immer auf dem neusten Stand. Ein moderner Fuhrpark garantiert ein Höchstmaß an technischer Verlässlichkeit bei allen Arbeitsprozessen.</p>
                <p>Wir wollen kontinuierlich wachsen, verändern, lernen, am besten gemeinsam mit unseren Kunden und Mitarbeitern.</p>
            </div>


        <div  style={{background: '#efefef'}}>
        <div className={styles.content_div}>
            <h2>Unsere Geschichte</h2>
            <div>
                <img className={styles.content_img_left} src={LS} alt="Werner Friedrich Wilhelm Lau" />
                <p>Werner Friedrich Wilhelm Lau (*1929 - + 2001) legte bereits früh den Grundstein für das heutige Unternehmen. Sein Name ist eng mit der Geschichte des Schwertransportes in der ehemaligen DDR verbunden.</p>
                <p>Bereits als junger Mann befuhr er mit einem „Kälble“ Deutschlands Straßen, eher er dann über 30 Jahre als Kraftfahrer und späterer Verkehrsmeister spektakuläre Schwertransporte beim VEB „Ostseetrans“ Rostock realisierte und damit ein Stück Geschichte der Schwerlastlogistik in Ostdeutschland schrieb und mitbestimmte. </p>
                <p>Der Name Lau ist somit eng mit dem Schwertransport verbunden.</p>
                <p>Im Frühjahr 1990 wurde die Firma „Schwertransport Werner Lau“ gegründet und zeitgleich baute er mit Frau Ingrid den nordostdeutschen Standort einer Schwerlastspedition in Rostock auf, von der man sich Ende 1994 trennte.</p>
                <p>Im Jahr 1995 wurde dann umfirmiert und das Unternehmen Schwertransport Lau Rostock in Form einer Einzelfirma gegründet. Im Jahr 2008 übernahm die Schwertransport Lau Rostock GmbH &amp; Co. KG alle operativen Geschäfte.</p>
                <p>Daneben existierten die Firmen Schwertransport Lau Rostock, Ingrid Lau und die Lau Immobilen, André Lau  uneingeschränkt weiter.</p>
                <p>In Zuge der Regelung zur Unternehmensnachfolge fusionierten die beiden Einzelunternehmen Schwertransport Lau Rostock, Ingrid Lau und die Lau Immobilien, André Lau im Jahr 2018 zur SVS Rostock, André Lau.</p>
                <p>Die SVS Rostock bietet seinen Kunden Schwertransport – Vermietung von Mobilen und Immobilien sowie Schulungen rund um das Thema Großraum- und Schwertransporte.</p>
                <p>Ebenfalls seit 2018 ist Herr André Lau alleiniger geschäftsführender Gesellschafter der Schwertransport Lau GmbH &amp; Co. KG und Frau Ingrid Lau zog sich aus allen Geschäften zurück.</p>
                <p>Die Lau Gruppe wurde und wird kontinuierlich auf- und ausgebaut.</p>
                <p>Wohin der Weg führt, bestimmen nicht zuletzt Sie, unsere Kunden…!</p>
            </div>
        </div>
        </div>
        <Parallax
            blur={{ min: -15, max: 15 }}
            bgImage={Raps}
            bgImageAlt="Landschaft"
            strength={-200}
        >
            <div className={styles.content_div} style={{ height: 'auto' }}>
            <h2>Umweltschutz und Nachhaltigkeit</h2>
            <div>
                <p>Das Thema Umweltschutz und Nachhaltigkeit hat auch in unserer Branche in den letzten Jahren immer mehr an Bedeutung gewonnen.</p>
                <p>Nachhaltige Unternehmensführung ist für uns Chefsache. In unserer Unternehmensgruppe wird jedoch nicht nur darüber gesprochen, sondern wir leben dies, jeden Tag.</p>
                <p>So ist es uns durch konsequente und intelligente Investitionen in den letzten Jahren gelungen, unseren gesamten Fuhrpark auf CO² - und Abgas reduzierte sowie verbrauchsarme EURO VI - Fahrzeuge umzustellen. Und das von den stärksten Zugmaschinen mit 160 Tonnen zulässigem Gesamtzuggewicht bis hin zum Transporter zur Begleitung und Sicherung Ihrer Transportvorhaben oder unseren Werkstattwagen.</p>
                <p>We move it! Think green!</p>

            </div>
            </div>
        </Parallax>
        </article>
    </Layout>
)